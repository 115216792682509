const pois = [
  {
    name: "Venture Coffee Co",
    slug: "venture-coffee",
    category: "Coffee",
    website: "https://venture-coffee.com/",
    location: "5819 24th Ave NW, Seattle, WA 98107",
    phone: "206-397-4552",
    image: "venture.jpg",
    his: "Latte",
    hers: "Iced Latte",
    recommendation:
      "Mention you're here for Angie & Garrett's wedding. They love us, so who knows, maybe they'll hook you up.",
  },
  {
    name: "Brimmer & Heeltap",
    slug: "brimmer-heeltap",
    category: "Brunch",
    website: "http://www.brimmerandheeltap.com/",
    location: "425 NW Market St, Seattle, WA 98107",
    phone: "206-420-2534",
    image: "brimmer.jpg",
    his: "Scotch Egg",
    hers: "Traditional breakfast with sausage",
    recommendation: "The Bread",
  },
  {
    name: "Fat's Chicken and Waffles",
    slug: "fats-chicken",
    category: "Brunch",
    website: "http://fatschickenandwaffles.com/",
    location: "2726 E Cherry St, Seattle, WA 98122",
    phone: "206-602-6863",
    image: "fats.jpg",
    his: "Honey Butter Chicken Biscuit Sandwich",
    hers: "Fat’s Eggs Benedict",
    recommendation: "They make the best bloody mary in Seattle",
  },
  {
    name: "FOB Poke Bar",
    slug: "fob-poke",
    category: "Lunch",
    website: "fobpokebar.com",
    location: "220 Blanchard St, Seattle, WA 98121",
    phone: "206-728-9888",
    image: "fob.jpg",
    his: "Salmon, Seaweed Salad, Crab Salad",
    hers: "Yellowtail, Salmon, FOB Rice",
    recommendation:
      "They'll give you both regular & spicy crab salad if you ask",
  },
  {
    name: "Un Bien",
    slug: "un-bien",
    category: "Lunch",
    website: "https://www.unbienseattle.com/",
    location: "6226 Seaview Ave NW, Seattle, WA 98107",
    phone: "206-420-7545",
    image: "un_bien.jpg",
    his: "Caribbean Roast Sandwich",
    hers: "Caribbean Roast Sandwich",
    recommendation: "Seriously... that sandwich is insane!",
  },
  {
    name: "Hotel Albatross",
    slug: "hotel-albatross",
    category: "Dinner",
    website: "http://www.hotelalbatross.com/",
    location: "2319 NW Market St, Seattle, WA 98107",
    phone: "206-566-6181",
    image: "albatross.jpg",
    his: "San Antonio Puffy Tacos",
    hers: "Classic Puffy Tacos",
    recommendation: "Totchoes",
  },
  {
    name: "San Fermo",
    slug: "san-fermo",
    category: "Dinner",
    website: "http://www.sanfermoseattle.com/",
    location: "5341 Ballard Ave NW, Seattle, WA 98107",
    phone: "206-342-1530",
    image: "san_fermo.jpg",
    his: "Mushroom Risotto",
    hers: "Saffron Bolognese",
    recommendation: "Ask for a delicious wine pairing",
  },
  {
    name: "Percy's & Co. Seattle",
    slug: "percys-co",
    category: "Drinks",
    website: "https://percysseattle.com/",
    location: "5233 Ballard Ave NW, Seattle, WA 98107",
    phone: "206-420-3750",
    image: "percys.jpeg",
    his: "Pisco Sour",
    hers: "Honey Bee",
    recommendation: "Hot Skillet Cornbread",
  },
  {
    name: "Bathtub Gin & Co.",
    slug: "bathtub-gin",
    category: "Drinks",
    website: "http://bathtubginseattle.com/",
    location: "2205 2nd Ave, Seattle, WA 98121",
    phone: "206-728-6069",
    image: "bathtub_gin.jpg",
    his: "Dirty Gin Martini",
    hers: "Death Star",
    recommendation: "Ask the bartender to mix something up for you",
  },
  {
    name: "Hot Cakes",
    slug: "hot-cakes",
    category: "Dessert",
    website: "https://getyourhotcakes.com/",
    location: "5427 Ballard Ave NW, Seattle, WA 98107",
    phone: "206-453-3792",
    image: "hot_cakes.png",
    his: "Milk Chocolate Hazelnut",
    hers: "Dark Decadence",
    recommendation: "Add a scoop of ice cream",
  },
  {
    name: "Salt & Straw",
    slug: "salt-straw",
    category: "Dessert",
    website: "https://saltandstraw.com/",
    location: "5420 Ballard Ave NW, Seattle, WA 98107",
    phone: "206-294-5581",
    image: "salt_straw.jpg",
    his: "Sea Salt & Caramel",
    hers: "Honey Lavender",
    recommendation: "Waffle cones",
  },
  {
    name: "Pho An",
    slug: "pho-an",
    category: "Lunch",
    website: "https://phoanlakecity.hrpos.heartland.us/",
    location: "12526 Lake City Way NE, Seattle, WA 98125",
    phone: "206-362-3439",
    image: "pho_an.jpg",
    his: "Brisket & Meatball Pho",
    hers: "Oxtail Pho with Meatballs",
    recommendation: "Fried Eggrolls",
  },
];

export default pois;

// {
//   name: "",
//   slug: "",
//   category: "",
//   website: "",
//   location: "",
//   phone: "",
//   image: "",
//   his: "",
//   hers: "",
//   recommendation: "",
// },
